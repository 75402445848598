import React from "react"
import {Link} from 'gatsby'

import { APP_URI } from '../configs';

import error from '../images/troop-error.svg'

import '../scss/error.scss'

export default class Error extends React.Component {

  render(){
    return(
    <div className="errorPage">

      <h1>      <img src={error} />No encontramos lo que buscabas :( <br/><a className="button--global" onClick={() => window.history.back()}>Volver</a>
      <Link to={'/'} className="button--global">Ir a la home</Link></h1>
    </div>
    );
  }
}
